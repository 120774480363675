import React from 'react';
import { Typography as T, Box } from '@fuji-ui/core';
import { Wrapper } from '../components/layout';
import RequestForm from '../components/request-form';
import SEO from '../components/seo';

const RequestPage = () => {
  return (
    <>
      <SEO title="Request" />
      <Box pt="xl">
        <Wrapper>
          <Box m="auto" textAlign="center" maxWidth={[null, 720, 720, null]}>
            <T.H1>Questions? Let us know 👋🏻</T.H1>
            <T.P>
              Based in Hong Kong, our team expert could help analyse your
              business and provide you with simple solutions. Please fill in the
              the contact form below and our representatives shall contact you
              very soon.
            </T.P>
          </Box>
          <RequestForm />
        </Wrapper>
      </Box>
    </>
  );
};

export default RequestPage;
